import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { Nil } from '@model';
import { JoyrideModule } from 'ngx-joyride';

import { TourCounterComponent } from '../tour-counter/tour-counter.component';
import { TourMessages } from '../tour.types';

@Component({
  selector: 'etn-tour-templates',
  templateUrl: './tour-templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, JoyrideModule, TourCounterComponent, MatButtonModule],
})
export class TourTemplatesComponent {
  @Input() public messages: TourMessages | Nil;
}
