import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Brand, FAVICON_MAPPING } from '@design-system';
import { Id } from '@model';
import {
  SideNavConfig,
  SideNavGroup,
  SideNavItem,
  SideNavVariant,
  SidenavComponent,
} from '@ui/sidenav';
import { ThemingModule } from '@ui/theming';
import { IpInfoApiService } from 'libs/api/src/lib';
import { capitalize, isEmpty } from 'lodash-es';

import { THEME_MAPPING } from '../themes';
import {
  CHARTS_COMPONENTS,
  LAYOUT_COMPONENTS,
  SERVICE_COMPONENTS,
  UI_COMPONENTS,
} from './app.utils';

@Component({
  selector: 'testbed-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SidenavComponent, ThemingModule, RouterModule],
  providers: [IpInfoApiService],
})
export class AppComponent {
  public sideNavConfig: SideNavConfig = {
    topItems: [
      {
        id: 'documentation',
        title: 'Documentation',
        routerLink: 'documentation',
        icon: 'help',
      },
      {
        id: 'signals',
        title: 'Signals',
        routerLink: 'signals',
        icon: 'help',
      },
      {
        id: 'i18n',
        title: 'Internationalization',
        routerLink: 'i18n',
        icon: 'language',
      },
    ],
    groups: [
      {
        id: 'layout-components',
        title: 'Layout Components',
        icon: 'list',
        items: this.getSideNavItems(LAYOUT_COMPONENTS),
      },
      {
        id: 'charts-components',
        title: 'Charts Components',
        icon: 'bar_chart',
        items: this.getSideNavItems(CHARTS_COMPONENTS),
      },
      {
        id: 'ui-components',
        title: 'UI Components',
        icon: 'widgets',
        items: this.getSideNavItems(UI_COMPONENTS),
      },
      {
        id: 'services-components',
        title: 'Services',
        icon: 'list',
        items: this.getSideNavItems(SERVICE_COMPONENTS),
      },
    ],
  };

  public sideNavActiveItemId: Id = this.getSideNavActiItemId(
    window.location.pathname,
  );
  public brand: Brand = 'eaton';
  public sideNavOpened = true;
  public sideNavVariant: SideNavVariant = 'persistent';
  public theme = `${THEME_MAPPING[this.brand]}.css`;
  public favicon = `logos/${this.brand}/${FAVICON_MAPPING[this.brand]}`;

  public onItemSelect(item: SideNavItem | SideNavGroup): void {
    setTimeout(() => {
      this.sideNavActiveItemId = item.id;
    }, 0);
  }

  public onToggle(): void {
    this.sideNavOpened = !this.sideNavOpened;
  }

  private getSideNavItems(components: string[]): SideNavItem[] {
    return components.map((component) => {
      const parts = component.split('-').map((part) => {
        return capitalize(part);
      });

      return {
        id: component,
        title: parts.join(' '),
        routerLink: component,
      };
    });
  }

  private getSideNavActiItemId(path: string): Id {
    const id = path.replace('/', '');
    return isEmpty(id) ? 'documentation' : id;
  }
}
