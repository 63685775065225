import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ViewContainerRef } from '@angular/core';
import { Nil } from '@model';
import { FabIconColor } from '@ui/fab-icon';
import { IconName } from '@ui/icon';
import { BehaviorSubject } from 'rxjs';

import { Action, ActionColor } from '../action';

export enum DialogAlign {
  Center = 'center',
}

export enum DialogPosition {
  Center = 'center',
  RightSide = 'right-side',
}

export enum DialogTitleColor {
  Primary = 'primary',
  Accent = 'accent',
  Warn = 'warn',
}

export type CloseDialogFn = () => void;
export type LoadingFn = (loading: boolean) => void;

export interface ConfirmAction<Ctx = any>
  extends Omit<Action<Ctx>, 'callback'> {
  callback?: (
    context: Ctx | Nil,
    loading: LoadingFn,
    closeDialog: CloseDialogFn,
  ) => void;
}

export interface DialogConfig<Data = any> {
  align?: DialogAlign;
  cancelAction?: Action;
  closeOnConfirm?: boolean;
  titleColor?: ActionColor;
  component?: ComponentPortal<AbstractDialogComponent>;
  confirmAction?: ConfirmAction<Data>;
  content?: string;
  data?: Data;
  fabIcon?: IconName;
  fabIconColor?: FabIconColor;
  hasBackdrop?: boolean;
  hint?: string;
  maxWidth?: number | string;
  minWidth?: number | string;
  onClose?: (data: Data) => void;
  panelClass?: string[];
  position?: DialogPosition;
  showTitleContentDivider?: boolean;
  title?: string;
  viewContainerRef?: ViewContainerRef;
}

@Component({
  template: '',
  standalone: false,
})
export abstract class AbstractDialogComponent<T = any> {
  public result: T | Nil;
  public valid$ = new BehaviorSubject<boolean>(true);
  public loading$ = new BehaviorSubject<boolean>(false);
}
