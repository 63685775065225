import { Brand } from '@design-system';

export const THEME_MAPPING: Record<Brand, string> = {
  eaton: 'eaton.1736925452155',
  emoti: 'emoti',
  evpass: 'evpass',
  greenmotion: 'greenmotion.1736925452160',
  neutral: 'neutral',
  nisko: 'nisko',
  residenza: 'residenza',
  valt: 'valt',
  smotion: 'smotion',
  vmotion: 'vmotion',
};
