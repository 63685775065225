import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { isNotNil } from '@core/is-not-nil';
import { FullCalendarModule } from '@fullcalendar/angular';
import {
  CalendarOptions,
  EventClickArg,
  EventHoveringArg,
  EventInput,
} from '@fullcalendar/core';
import { VerboseFormattingArg } from '@fullcalendar/core/internal';
import { ResourceInput } from '@fullcalendar/resource';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import rrulePlugin from '@fullcalendar/rrule';
import { Nil } from '@model';
import { TooltipPosition } from '@ui/tooltip/tooltip.types';
import { format } from 'date-fns';

import { SCHEDULER_LICENSE_KEY } from './time-line.provider';
import { Resource, ResourceEvent } from './time-line.types';

@Component({
  selector: 'etn-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FullCalendarModule],
})
export class TimeLineComponent implements OnChanges {
  public constructor() {
    this.licenseKey = inject(SCHEDULER_LICENSE_KEY);
  }

  @Input() public headerTitle: string | Nil;
  @Input() public resources: Resource[] | Nil;
  @Input() public events: ResourceEvent[] | Nil;
  @Input() public nowIndicatorDate: Date | Nil;
  @Input() public tooltipPosition: TooltipPosition = 'bottom';
  @Input() public timeFormat = 'HH:mm';

  @Output() public eventClick = new EventEmitter<ResourceEvent>();

  private nowTimezone = new Date();

  private licenseKey: string;

  public calendarOptions: CalendarOptions = this.getCalendarOptions(null, null);

  public ngOnChanges(changes: SimpleChanges): void {
    if (isNotNil(changes.nowIndicatorDate?.currentValue)) {
      this.nowTimezone = changes.nowIndicatorDate?.currentValue;
    }

    if (isNotNil(changes.resources) || isNotNil(changes.events)) {
      this.calendarOptions = this.getCalendarOptions(
        this.resources,
        this.events,
      );
    }
  }

  public getCalendarOptions(
    rows: Resource[] | Nil,
    items: ResourceEvent[] | Nil,
  ): CalendarOptions {
    return {
      initialView: 'resourceTimeline',
      plugins: [resourceTimelinePlugin, rrulePlugin],
      nowIndicator: true,
      now: this.nowTimezone,
      headerToolbar: false,
      footerToolbar: false,
      editable: true,
      selectable: false,
      eventMinHeight: 50,
      schedulerLicenseKey: this.licenseKey,
      slotEventOverlap: true,
      resourceAreaWidth: 210,
      height: 'auto',
      timeZone: 'UTC',
      slotLabelFormat: (arg: VerboseFormattingArg) => {
        return format(arg.date.marker.getUTCDate(), this.timeFormat);
      },
      scrollTime: {
        hours: 9,
      },
      slotLabelInterval: {
        hours: 2,
      },
      resources: (rows ?? []).map((r) => {
        return this.getResource(r);
      }),
      events: (items ?? []).map((e) => {
        return this.getEvent(e);
      }),
      eventMouseEnter: (info: EventHoveringArg) => {
        info.el.setAttribute('title', info.event.extendedProps.tooltip);
      },
      eventClick: ($event: EventClickArg) => {
        const e = (items ?? []).find((x) => {
          return x.id === $event.event.id;
        });
        if (isNotNil(e)) {
          this.eventClick.emit(e);
        }
      },
    };
  }

  private getResource(value: Resource): ResourceInput {
    return {
      id: value.id,
      title: value.name,
      nowIndicator: true,
      eventClassNames: value.color,
    };
  }

  public getEvent(e: ResourceEvent): EventInput {
    let item: EventInput = {
      id: e.id,
      resourceId: e.resourceId,
      title: e.name,
      extendedProps: {
        description: e.description,
        tooltip: e.tooltip,
      },
      classNames: e.color,
    };

    if (e.rrule && e.duration) {
      item.rrule = e.rrule;
      item.duration = e.duration;
    } else if (e.start && e.end) {
      item.start = e.start;
      item.end = e.end;
    }

    return item;
  }
}
